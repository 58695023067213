.canvas-container {
  display: none;
}

.summary__img.summary-preview .summary__img-box img {
  max-height: 100%;
  width: 100%;
  margin: 0 auto;
}
.summary__img.summary-preview .footer-panel {
  margin-top: 10px;
}
.simplebar-track {
  right: 2px !important;
}
.summary__panel-item-disable {
  opacity: 0.8;
}
.footer-panel__function-btn {
  text-transform: uppercase;
}
/*.summary__img.summary-preview {*/
/*  display: none;*/
/*}*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c7b299;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;

  opacity: 0;
}

.loader.show {
  display: flex;
  opacity: 1;
  transition: all 2s linear;
}
canvas#my-door,
canvas#my-door-mobile {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
}
.summary__img.summary-preview.gallery .canvas-container {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  display: block;
}
.summary__img.summary-preview.gallery .canvas-container canvas {
  height: 100% !important;
}
.global-btn.global-btn-arrow {
  width: 100%;
  position: relative;
  max-width: 100%;
  background-color: #22b573;
  font-size: 10px;
  letter-spacing: 0.11em;
  line-height: 12px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 10px;
}
.global-btn.global-btn-arrow input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.main-box-content__inner-list-listbox li a.active {
  font-weight: 700;
}
.summary__img.summary-preview .footer-panel {
  z-index: 2;
}
.summary__img.summary-preview {
  overflow: hidden;
}
@media (max-width: 500px) {
  .main-box-content__inner.mobile-image .summary__con-inner {
    display: none;
  }
  .main-box-content__inner.mobile-image .summary__img.summary-preview {
    display: flex;
    height: 100%;
  }
}
.preview-door__img-box {
  overflow: hidden;
}
.preview-door__img-box img {
  object-fit: contain !important;
}

.build__items-inner.medium-height .build__item.glass .build__item-img {
  height: 120px;
}
.build__item.active::before {
  display: block;
  z-index: 100;
}
#canvas-builder {
  display: none;
}
